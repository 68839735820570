import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ofType } from '@ngneat/effects';
import { Actions } from '@ngneat/effects-ng';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { login } from './state/auth.actions';

@UntilDestroy()
@Component({
  selector: 'tl-auth',
  templateUrl: './auth.component.html',
  styles: [
    `
      input {
        width: 250px;
      }
    `,
  ],
})
export class AuthComponent implements OnInit {
  public form!: FormGroup;
  public error: unknown;
  public errorDate?: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly actions: Actions,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.actions
      .pipe(ofType(login.successAction), untilDestroyed(this))
      .subscribe(() => this.router.navigateByUrl(''));

    this.actions
      .pipe(ofType(login.failAction), untilDestroyed(this))
      .subscribe((error) => {
        (this.errorDate = new Date().toISOString()), (this.error = error);
      });

    this.form = this.fb.group({
      login: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  login() {
    this.error = undefined;
    this.actions.dispatch(
      login.action({
        login: this.form?.get('login')?.value,
        password: this.form?.get('password')?.value,
      })
    );
  }
}
