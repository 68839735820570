import {
  Component,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Actions } from '@ngneat/effects-ng';
import { Observable } from 'rxjs';
import { CRUDRepository, UILookupEntity } from '../../crud/repository';
import { randomId } from '../../utils/random-id-generator';

@Component({
  selector: 'tl-selector-form-control',
  template: `
    <ng-template #template>
      <nz-form-label [nzSm]="captionSm" [nzXs]="24" [nzFor]="id">{{
        caption
      }}</nz-form-label>
      <nz-form-control [nzSm]="editorSm" [nzXs]="24">
        <input
          nz-input
          *ngIf="!editMode"
          readonly="true"
          [value]="textValue"
          placeholder="-"
        />
        <nz-select
          *ngIf="editMode"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="editMode ? '' : '-'"
          [nzDropdownRender]="renderTemplate"
          [nzLoading]="(valueRepository.listLoaded$ | async) === false"
          [ngModel]="value"
          (ngModelChange)="valueChanged($event)"
          [id]="id"
        >
          <nz-option
            *ngFor="
              let option of lookupEntities || valueRepository.lookupEntities$
                | async
            "
            [nzCustomContent]="!!option.tag && showTag !== false"
            [nzLabel]="option.label"
            [nzValue]="option.value"
          >
            <nz-tag [nzColor]="option.tagColor" [style.width]="tagWidth">{{
              option.tag
            }}</nz-tag>
            {{ option.label }}</nz-option
          >
        </nz-select>
        <ng-template #renderTemplate>
          <div class="toolbar-bottom" *ngIf="editMode">
            <nz-button-group>
              <button disabled nz-button nzType="text">
                <span
                  style="
                              color: silver;
                              font-size: 12px;
                              padding-right: 7px;
                            "
                  ><i nz-icon nzType="plus"></i
                ></span>
                Добавить
              </button>
              <button nz-button nzType="text" (click)="refreshValues()">
                <span
                  class="ant-btn-text"
                  style="
                              font-size: 12px;
                              padding-right: 7px;
                            "
                  ><i nz-icon nzType="sync"></i
                ></span>
                Обновить
              </button>
            </nz-button-group>
          </div>
        </ng-template>
      </nz-form-control>
    </ng-template>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorFormControlComponent),
      multi: true,
    },
  ],
})
export class SelectorFormControlComponent
  implements ControlValueAccessor, OnInit {
  @Input() caption = '';
  @Input() editMode = false;
  @Input() textValue: string | undefined = '';
  @Input() valueRepository!: CRUDRepository;
  @Input() captionSm = 5;
  @Input() editorSm = 19;
  @Input() showTag = true;
  @Input() tagWidth: string | null = null;
  @Input() itemData: ((item: any) => { label?: string }) | null = null;
  @Input() lookupEntities: Observable<UILookupEntity[]> | undefined;

  @ViewChild('template', { static: true }) template!: TemplateRef<never>;
  @ViewChild('spanTemplate', { static: true })
  spanTemplate?: TemplateRef<never>;

  public value: null | string = null;
  public id = randomId();
  public customTemplate: TemplateRef<never> | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private readonly actions: Actions
  ) { }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.customTemplate = this.spanTemplate || null;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: string) => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: unknown = () => { };

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: (_: string) => {}): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: (_: string) => {}): void {
    this.onTouch = fn;
  }

  writeValue(value: null | string): void {
    this.value = value;
  }

  valueChanged(value: string) {
    this.onChange(value);
  }

  refreshValues() {
    this.valueRepository.reloadList(this.actions);
  }
}
