import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'substance';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка веществ',
  successMessage: 'Список веществ загружен',
  failMessage: 'Ошибка при загрузке списка веществ',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка вещества',
  successMessage: 'Вещество загружена',
  failMessage: 'Ошибка при загрузке вещества',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание вещества',
  successMessage: 'Вещество создана',
  failMessage: 'Ошибка при создании вещества',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление вещества',
  successMessage: 'Вещество обновлена',
  failMessage: 'Ошибка при обновлении вещества',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление вещества',
  successMessage: 'Вещество удалена',
  failMessage: 'Ошибка при удалении вещества',
});
