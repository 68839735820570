import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthRepository } from './state/auth.repository';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfLoggedIn]',
})
export class IsLoggedInDirective implements OnInit {
  @Input('showIfLoggedIn') renderTemplate = true;

  constructor(
    private repository: AuthRepository,
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<unknown>
  ) {}

  ngOnInit() {
    this.repository.isLoggedIn$
      .pipe(untilDestroyed(this))
      .subscribe((isLoggedIn) => {
        this.vcr.clear();
        if (isLoggedIn && this.renderTemplate) {
          this.vcr.createEmbeddedView(this.tpl);
        }

        if (!isLoggedIn && !this.renderTemplate) {
          this.vcr.createEmbeddedView(this.tpl);
        }
      });
  }
}
