import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'testMethod';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка методов исследований',
  successMessage: 'Методы исследований загружены',
  failMessage: 'Ошибка при загрузке методов исследований',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка метода исследований',
  successMessage: 'Метод исследований загружена',
  failMessage: 'Ошибка при загрузке метода исследований',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание метода исследований',
  successMessage: 'Метод исследований создана',
  failMessage: 'Ошибка при создании метода исследований',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление метода исследований',
  successMessage: 'Метод исследований обновлена',
  failMessage: 'Ошибка при обновлении метода исследований',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление метода исследований',
  successMessage: 'Метод исследований удалена',
  failMessage: 'Ошибка при удалении метода исследований',
});
