import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { AuthRepository, RefreshingTokenType } from './state/auth.repository';
import { AuthService, hasAuthError } from './state/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly appService: AppService,
    private readonly authService: AuthService,
    private readonly authRepository: AuthRepository
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let access_token: string | null;
    let refreshingToken: RefreshingTokenType = false;

    const setHeaders = (r: HttpRequest<unknown>): HttpRequest<unknown> => {
      const access_token = this.authRepository.access_token();
      if (access_token) {
        return r.clone({
          setHeaders: {
            'X-API-Key': `${access_token}`,
          },
        });
      }
      return r;
    };

    if (req.url.startsWith(environment.api_endpoint)) {
      refreshingToken = this.authRepository.refreshingToken();
    }

    const tryRefreshTokenOrLogout = () =>
      this.authService
        .refreshToken(
          this.authRepository.refresh_token() || '',
          'from_interceptor'
        )
        .pipe(
          switchMap(() => {
            return next.handle(setHeaders(req)).pipe(
              tap((event) => {
                if (event instanceof HttpResponse) {
                  if (hasAuthError(event?.body?.errors)) {
                    // if (this.appService.inited) {
                    this.router.navigateByUrl('login');
                    // }
                  }
                }
              }),
              catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                  if (error && error.status === 401) {
                    this.router.navigateByUrl('login');
                    return new Observable<HttpEvent<unknown>>();
                  } else {
                    return throwError(() => error);
                  }
                }
                return throwError(() => error);
              })
            );
          })
        );

    return next
      .handle(setHeaders(req))
      .pipe
      // switchMap((event) => {
      //   if (
      //     refreshingToken !== 'from_interceptor' &&
      //     event instanceof HttpResponse
      //   ) {
      //     if (hasAuthError(event?.body?.errors)) {
      //       if (refreshingToken === 'initial') {
      //         this.authRepository.clearTokens();
      //         return of(event);
      //       } else {
      //         console.error('----Trying to refresh token: ', event);
      //         return tryRefreshTokenOrLogout();
      //       }
      //     }
      //   }
      //   return of(event);
      // }),
      // catchError((error) => {
      //   if (error instanceof HttpErrorResponse) {
      //     if (error && error.status === 401) {
      //       if (!access_token) {
      //         // Точно нужно залогиниться
      //         this.router.navigateByUrl('login');
      //         return new Observable<HttpEvent<unknown>>();
      //       } else {
      //         return tryRefreshTokenOrLogout();
      //       }
      //     } else {
      //       return throwError(() => error);
      //     }
      //   }
      //   if (error.graphQLErrors) {
      //     if (hasAuthError(error.graphQLErrors)) {
      //       this.router.navigateByUrl('login');
      //       return of();
      //     }
      //   }
      //   return throwError(() => error);
      // })
      ();
  }
}
