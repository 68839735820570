import { APP_INITIALIZER, Provider } from '@angular/core';
import { catchError, of, throwError } from 'rxjs';
import { AuthRepository } from './state/auth.repository';
import { AuthService, isInvalidRefreshToken } from './state/auth.service';

export const InitAuthProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory:
    (authService: AuthService, authRepository: AuthRepository) => () =>
      authService
        .refreshToken(authRepository.refresh_token() ?? '', 'initial')
        .pipe(
          catchError((err) => {
            if (isInvalidRefreshToken(err?.graphQLErrors ?? [])) {
              authRepository.clearTokens();
              return of();
            }
            return throwError(() => err);
          })
        ),
  multi: true,
  deps: [AuthService, AuthRepository],
};
