import { capitalize } from '@ngneat/elf';
import { Apollo, gql } from 'apollo-angular';
import { IChange } from 'json-diff-ts';
import { map } from 'rxjs';
import { objChanges } from '../utils/changes';
import { changesToGraphQL } from '../utils/changes-to-graphql';

export class CRUDService<
  T extends { [P in IdKey]: string },
  TE extends T = T,
  IdKey extends string = 'id'
> {
  constructor(
    public readonly apollo: Apollo,
    public readonly options: {
      singular: string;
      plural: string;
      list: string;
      entity?: string;
      idKey?: string;
    }
  ) {}

  idKey() {
    return this.options.idKey || 'id';
  }

  idKeyForChanges() {
    return this.idKey();
  }

  findAll() {
    const entities = this.options.plural;
    return (
      this.apollo
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .query<any>({
          query: gql`
          {
            ${entities} {
              ${this.options.list}
            }
          } 
        `,
          fetchPolicy: 'no-cache',
        })
        .pipe(map((res) => (res.data?.[entities] as T[]) || ([] as TE[])))
    );
  }

  findOne(id: string) {
    return (
      this.apollo
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .query<any>({
          query: gql`
          query ${this.options.singular}($id: String!) {
            ${this.options.singular} (id: $id) {
              ${this.options.entity || this.options.list}
            }
          } 
        `,
          variables: { id: id },
          fetchPolicy: 'no-cache',
        })
        .pipe(map((res) => res.data?.[this.options.singular] as TE))
    );
  }

  create(entity: T) {
    const requestName = this.options.singular + 'Create';
    const inputName = capitalize(this.options.singular) + 'CreateInput';

    const oldCompareObj: any = {};

    for (const key in entity) {
      if (Array.isArray(entity[key])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((entity[key] as any).length < 1) {
          delete entity[key];
        } else {
          oldCompareObj[key] = [];
        }
      }
      if (entity[key] === undefined) {
        delete entity[key];
      }
    }
    const changes = objChanges(oldCompareObj, entity, this.idKeyForChanges());
    const graphQLEntity = changesToGraphQL(changes, false);
    return (
      this.apollo
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .mutate<any>({
          mutation: gql`
          mutation ${requestName}($entity: ${inputName}!) {
            ${requestName}(${this.options.singular}: $entity) {
              ${this.options.entity || this.options.list}
            }
          }
        `,
          variables: {
            entity: graphQLEntity,
          },
        })
        .pipe(map((res) => res.data?.[requestName] as T))
    );
  }

  update(id: T[IdKey], changes: IChange[]) {
    const requestName = this.options.singular + 'Update';
    const inputName = capitalize(this.options.singular) + 'UpdateInput';
    const settablePatch = changesToGraphQL(changes, true);
    return (
      this.apollo
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .mutate<any>({
          mutation: gql`
          mutation ${requestName}($id: String!, $patch: ${inputName}!) {
            ${requestName}(id: $id, patch: $patch) {
              ${this.options.entity || this.options.list}
            }
          }
        `,
          variables: {
            id: id,
            patch: settablePatch,
          },
        })
        .pipe(map((res) => res.data?.[requestName] as T))
    );
  }

  delete(id: T[IdKey]) {
    const requestName = this.options.singular + 'Delete';
    return (
      this.apollo
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .mutate<any>({
          mutation: gql`
          mutation ${requestName}($id: String!) {
            ${requestName}(id: $id) {
              ${this.options.entity || this.options.list}
            }
          }
        `,
          variables: {
            id: id,
          },
        })
        .pipe(map((res) => res.data?.[requestName] as T))
    );
  }
}
