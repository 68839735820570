import { Injectable } from '@angular/core';
import { MaterialProperty, Prisma } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';
import { NoAudit } from '../../shared/interfaces/utils';
import { MaterialPropertyListItemGQL } from './material-property.interface';

const listProperties = `
  ${Prisma.MaterialPropertyScalarFieldEnum.id}
  ${Prisma.MaterialPropertyScalarFieldEnum.name}
  ${Prisma.MaterialPropertyScalarFieldEnum.quantityId}
  quantity {
    ${Prisma.QuantityScalarFieldEnum.id}
    ${Prisma.QuantityScalarFieldEnum.name}
  }
  ${Prisma.MaterialPropertyScalarFieldEnum.substanceId}
  substance {
    ${Prisma.SubstanceScalarFieldEnum.id}
    ${Prisma.SubstanceScalarFieldEnum.name}
  }
`;

const entityProperies =
  listProperties +
  `
  testMethods {
    ${Prisma.MaterialPropertyTestMethodScalarFieldEnum.id}
    ${Prisma.MaterialPropertyTestMethodScalarFieldEnum.materialPropertyId}
    ${Prisma.MaterialPropertyTestMethodScalarFieldEnum.testMethodId}
    testMethod {
      ${Prisma.TestMethodScalarFieldEnum.name}
      technicalDocument {
        ${Prisma.TechnicalDocumentScalarFieldEnum.name}
        ${Prisma.TechnicalDocumentScalarFieldEnum.title}
      }
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class MaterialPropertyService extends CRUDService<
  NoAudit<MaterialProperty>,
  MaterialPropertyListItemGQL
> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'materialProperty',
      plural: 'materialProperties',
      list: listProperties,
      entity: entityProperies,
    });
  }
}
