import { Actions } from '@ngneat/effects';
import { Store } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  getAllEntities,
  selectActiveEntity,
  selectActiveId,
  selectAllEntities,
  setActiveId,
  setEntities,
  updateEntities,
} from '@ngneat/elf-entities';
import {
  selectRequestStatus,
  StatusState,
  updateRequestStatus,
} from '@ngneat/elf-requests';
import { BehaviorSubject, combineLatestWith } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { loadListActions } from './actions';

type StatusPaths = 'list' | 'item';

export interface UILookupEntity<
  TEntity extends { [P in IdKey]: string } = any,
  IdKey extends string = 'id'
> {
  label: string;
  value: string;
  tag?: string;
  tagColor?: string;
  entity: TEntity;
}

export class CRUDRepository<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEntity extends { [P in IdKey]: string } = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TStore extends Store = any,
  IdKey extends string = 'id'
> {
  constructor(
    public readonly store: TStore,
    public readonly options: {
      loadListAction: ReturnType<typeof loadListActions>;
      idKey?: string;
    }
  ) {}

  entities$ = this.store.pipe(
    selectAllEntities(),
    shareReplay({ refCount: true })
  );
  active$ = this.store.pipe(
    selectActiveEntity(),
    shareReplay({ refCount: true })
  );
  activeId$ = this.store.pipe(
    selectActiveId(),
    shareReplay({ refCount: true })
  );

  searchText$ = new BehaviorSubject<string | null | undefined>('');

  itemLoading$ = this.store.pipe(
    selectRequestStatus('item'),
    map((result) => result.value === 'pending'),
    shareReplay({ refCount: true })
  );

  listLoading$ = this.store.pipe(
    selectRequestStatus('list'),
    map((result) => result.value === 'pending'),
    shareReplay({ refCount: true })
  );

  listLoaded$ = this.store.pipe(
    selectRequestStatus('list'),
    map((result) => result.value === 'success'),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  searchEntities$ = this.listLoaded$.pipe(
    combineLatestWith(this.searchText$),
    filter(([loaded]) => loaded),
    map(([, searchText]) => {
      const result = this.entities().filter((entity) => {
        if (!searchText) return entity;
        return this.searchEntity(entity, searchText);
      });
      return result;
    }),
    shareReplay({ refCount: true })
  );

  public lookupEntities$ = this.listLoaded$.pipe(
    combineLatestWith(this.entities$),
    filter(([loaded]) => loaded),
    map(([, entities]) => {
      const result = entities.map((entity) => {
        const item: UILookupEntity<TEntity, IdKey> = {
          label: this.lookupLabel(entity),
          value: this.lookupValue(entity),
          tag: this.lookupTag(entity),
          tagColor: this.lookupTagColor(entity),
          entity: entity,
        };
        return item;
      });
      result.sort((a, b) => {
        const aText = a.label?.toLowerCase() || '';
        const bText = b.label?.toLowerCase() || '';
        if (aText > bText) return 1;
        if (aText < bText) return -1;
        return 0;
      });
      return result;
    }),
    shareReplay({ refCount: true })
  );

  public idKey() {
    return this.options.idKey || 'id';
  }

  public idValue(entity: TEntity) {
    const idKey = this.idKey();
    return (entity as never)[idKey] as string;
  }

  public searchEntity(entity: TEntity, searchText: string): boolean {
    return true;
  }

  public lookupLabel(entity: TEntity): string {
    return (
      (entity as never)['systemName'] ?? (entity as never)['name'] ?? '???'
    );
  }

  public lookupValue(entity: TEntity): string {
    return this.idValue(entity);
  }

  public lookupTag(entity: TEntity): string | undefined {
    return undefined;
  }

  public lookupTagColor(entity: TEntity): string | undefined {
    return undefined;
  }

  public linkData(entity: TEntity) {
    return {
      link: this.linkToEntity(entity),
      text: this.linkText(entity),
      state: this.linkState(entity),
    };
  }

  public linkToEntity(entity: TEntity) {
    return this.idValue(entity);
  }

  public linkState(entity: TEntity): any {
    return undefined;
  }

  public linkText(entity: TEntity): string | undefined {
    return undefined;
  }

  public listLoaded() {
    return this.store.getValue()?.requestsStatus?.list?.value === 'success';
  }

  public entities(): TEntity[] {
    return this.store.query(getAllEntities());
  }

  public byId(id: string) {
    return this.store.getValue().entities?.[id];
  }

  public lookupName(id: string): string {
    const entity = this.entityById(id) as any;
    return entity?.systemName || entity?.name || '';
  }

  public entityById(id: string): TEntity {
    return this.store.getValue().entities?.[id];
  }

  public needListLoaded(actions: Actions) {
    if (!this.listLoaded()) {
      actions.dispatch(this.options.loadListAction.action());
    }
  }

  public reloadList(actions: Actions) {
    actions.dispatch(this.options.loadListAction.action());
  }

  public updateStatus(
    path: StatusPaths,
    status: Exclude<StatusState['value'], 'error'>
  ) {
    this.store.update(updateRequestStatus(path, status));
  }

  public updateStatusError(path: StatusPaths, error: unknown) {
    this.store.update(updateRequestStatus(path, 'error', error));
  }

  public setEntities(
    entities: TEntity[],
    status: Exclude<StatusState['value'], 'error'> = 'success'
  ) {
    this.store.update(
      setEntities(entities),
      status ? updateRequestStatus('list', status) : (state) => state
    );
  }

  public addEntity(
    entity: TEntity,
    status: Exclude<StatusState['value'], 'error'> = 'success'
  ) {
    this.store.update(
      addEntities(entity),
      status ? updateRequestStatus('item', status) : (state) => state
    );
  }

  public updateEntity(
    id: TEntity[IdKey],
    entity: Partial<TEntity>,
    status: Exclude<StatusState['value'], 'error'> = 'success'
  ) {
    this.store.update(
      updateEntities(id, entity),
      status ? updateRequestStatus('item', status) : (state) => state
    );
  }

  public deleteEntity(
    id: TEntity[IdKey],
    status: Exclude<StatusState['value'], 'error'> = 'success'
  ) {
    this.store.update(
      deleteEntities(id),
      status ? updateRequestStatus('item', status) : (state) => state
    );
  }

  public setActiveEntityId(id: TEntity[IdKey]) {
    this.store.update(setActiveId(id));
  }

  public getActiveEntityId() {
    return this.store.getValue().activeId;
  }
}
