import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Person } from '@prisma/client';
import { map } from 'rxjs';
import { CRUDRepository } from '../../shared/crud/repository';
import { loadList } from './person.actions';

type StatusPaths = 'list' | 'item';

const { state, config } = createState(
  withEntities<Person>(),
  withActiveId(),
  withRequestsStatus<StatusPaths>()
);

export const store = new Store({ name: 'person', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class PersonRepository extends CRUDRepository<Person, typeof store> {
  public lookupPhysical$ = this.lookupEntities$.pipe(
    map((entities) =>
      entities.filter((item) => item.entity.type === 'physical')
    )
  );

  public lookupLegalOrIP$ = this.lookupEntities$.pipe(
    map((entities) =>
      entities.filter(
        (item) => item.entity.type === 'legal' || item.entity.type === 'ip'
      )
    )
  );

  constructor() {
    super(store, { loadListAction: loadList });
  }

  public override searchEntity(entity: Person, searchText: string) {
    searchText = searchText.toLowerCase();
    if (entity.name) {
      if (entity.name?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.taxID) {
      if (entity.taxID?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.ogrn) {
      if (entity.ogrn?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    return false;
  }

  public override lookupTag(entity: Person) {
    switch (entity.type) {
      case 'physical':
        return 'ФЛ';
      case 'ip':
        return 'ИП';
      case 'legal':
        return 'ЮЛ';
    }
    return '??';
  }

  public override lookupTagColor(entity: Person) {
    switch (entity.type) {
      case 'physical':
        return 'purple';
      case 'ip':
        return 'blue';
      case 'legal':
        return 'green';
    }
  }

  public override linkState(entity: Person): any {
    return entity.type ? { entity: { type: entity.type } } : undefined;
  }
}
