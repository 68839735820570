import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'equipment';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка оборудования',
  successMessage: 'Список оборудование загружен',
  failMessage: 'Ошибка при загрузке списка оборудования',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка оборудования',
  successMessage: 'Оборудование загружено',
  failMessage: 'Ошибка при загрузке оборудования',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание нового оборудования',
  successMessage: 'Новое оборудование создано',
  failMessage: 'Ошибка при создании нового оборудования',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление оборудования',
  successMessage: 'Оборудование обновлено',
  failMessage: 'Ошибка при обновление оборудования',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление оборудования',
  successMessage: 'Оборудование удалено',
  failMessage: 'Ошибка при удалении оборудования',
});
