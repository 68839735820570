import { Pipe, PipeTransform } from '@angular/core';
import { Agreement } from '@prisma/client';
import { agreementName } from './agreement.utils';

@Pipe({
  name: 'agreementName',
})
export class AgreementNamePipe implements PipeTransform {
  transform(value: Agreement | undefined, args?: any): string {
    return value ? agreementName(value) : '';
  }
}
