import { TechnicalDocument } from '.prisma/client';
import { Injectable } from '@angular/core';
import { CreateHotToastRef, HotToastService } from '@ngneat/hot-toast';
import { of, Subject } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  createItemEffect,
  deleteItemEffect,
  loadListEffect,
  updateItemEffect,
} from '../../shared/crud/effects';
import {
  createItem,
  deleteItem,
  loadList,
  updateItem,
} from './technical-document.actions';
import { TechnicalDocumentRepository } from './technical-document.repository';
import { TechnicalDocumentService } from './technical-document.service';

@Injectable({ providedIn: 'root' })
export class TechnicalDocumentEffects {
  public notify$ = new Subject<{
    action: { type: string };
    payload?: unknown;
  }>();

  constructor(
    private repository: TechnicalDocumentRepository,
    private service: TechnicalDocumentService,
    private toast: HotToastService
  ) {}

  loadEntities$ = loadListEffect<TechnicalDocument>({
    action: loadList,
    repository: this.repository,
    serviceAction: () => this.service.findAll(),
    toast: this.toast,
  });

  createEntity$ = createItemEffect<TechnicalDocument>({
    action: createItem,
    repository: this.repository,
    serviceAction: (entity) => this.service.create(entity),
    toast: this.toast,
  });

  updateEntity$ = updateItemEffect<TechnicalDocument>({
    action: updateItem,
    repository: this.repository,
    serviceAction: (id, changes) => this.service.update(id, changes),
    toast: this.toast,
  });

  deleteEntity$ = deleteItemEffect<TechnicalDocument>({
    action: deleteItem,
    repository: this.repository,
    serviceAction: (id) => this.service.delete(id),
    toast: this.toast,
  });

  loadEntityById$ = (id: TechnicalDocument['id']) => {
    let toaster: CreateHotToastRef<unknown>;
    return of(id).pipe(
      switchMap((id) => {
        this.repository.updateStatus('item', 'pending');
        environment?.production ||
          (toaster = this.toast.loading(`Загрузка технического документа`));
        return this.service.findOne(id).pipe(
          tap((entity) => {
            if (!environment?.production) {
              this.toast.success(`Технический документ загружен`);
            }
            if (entity) {
              this.repository.updateEntity(entity.id, entity);
            }
          }),
          finalize(() => {
            toaster?.close();
          })
        );
      })
    );
  };
}
