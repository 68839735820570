import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsNgModule } from '@ngneat/effects-ng';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { AuthComponent } from './auth.component';
import { IsLoggedInDirective } from './auth.directive';
import { AuthGuard } from './auth.guard';
import { AuthEffects } from './state/auth.effects';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    NzInputModule,
    NzAlertModule,
    NzGridModule,
    EffectsNgModule.forFeature([AuthEffects]),
  ],
  declarations: [AuthComponent, IsLoggedInDirective],
  providers: [AuthGuard],
  exports: [AuthComponent, IsLoggedInDirective],
})
export class AuthModule {}
