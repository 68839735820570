import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'tl-section-title',
  template: `
    <nz-tabset
      nzType="card"
      [style.margin-top]="marginTop"
      [nzTabBarExtraContent]="extraContent"
    >
      <nz-tab [nzTitle]="title"></nz-tab>
    </nz-tabset>
  `,
  styles: [
    `
      ::ng-deep .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        background: #fff;
      }
      ::ng-deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #0082f4;
      }
      ::ng-deep .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        border: 1px solid #edf3f8;
      }
      ::ng-deep .ant-tabs-top > .ant-tabs-nav::before,
      .ant-tabs-bottom > .ant-tabs-nav::before,
      .ant-tabs-top > div > .ant-tabs-nav::before,
      .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: 1px solid #edf3f8;
      }
    `,
  ],
})
export class SectionTitleComponent {
  @Input() title = '???';
  @Input() marginTop: string | null = null;
  @Input() tiny = false;
  @Input() extraContent: TemplateRef<void> | undefined;
}
