export function randomId(length = 10) {
  const result = [];
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join('');
}

export const tempIdPrefix = '~~';

export function tempId() {
  return tempIdPrefix + randomId();
}

export function isTempId(id: string) {
  return id ? id.substring(0, tempIdPrefix.length) === tempIdPrefix : false;
}
