import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsNgModule } from '@ngneat/effects-ng';
import { HotToastModule } from '@ngneat/hot-toast';
import { HotkeysModule } from '@ngneat/hotkeys';
import { AgGridModule } from 'ag-grid-angular';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { AgreementNamePipe } from '../agreement/agreement.pipe';
import { AgGridCellLinkComponent } from './components/ag-grid-cell-link/ag-grid-cell-link.component';
import { AgGridCheckboxComponent } from './components/ag-grid-checkbox/ag-grid-checkbox.component';
import { AgGridCellDatePickerComponent } from './components/ag-grid-date-picker/ag-grid-date-picker.component';
import { AgGridCellSelectorComponent } from './components/ag-grid-selector/ag-grid-selector.component';
import { SearchStoreInputComponent } from './components/search-store-input/search-store-input.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { SelectorFormControlComponent } from './components/selector-field/selector-form-control.component';
import { SelectorStoreDirective } from './components/selector-store/selector-store.component';
import { AutofocusDirective } from './directive.autofocus';
import { IconsProviderModule } from './icons-provider.module';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,

  NzPageHeaderModule,
  NzCardModule,
  NzButtonModule,
  NzIconModule,
  NzBreadCrumbModule,
  NzFormModule,
  NzInputModule,
  NzDatePickerModule,
  NzLayoutModule,
  NzMenuModule,
  NzSelectModule,
  NzCheckboxModule,
  NzSwitchModule,
  NzTabsModule,
  NzTagModule,
  NzAlertModule,
  NzPopconfirmModule,
  NzEmptyModule,
  NzRadioModule,
  NzAutocompleteModule,
  NzDividerModule,
  NzToolTipModule,
  NzAnchorModule,
  NzSpaceModule,
  NzTypographyModule,
  NzDropDownModule,
  NzAffixModule,
  NzBadgeModule,

  NzNoAnimationModule,

  IconsProviderModule,
  HotkeysModule,
];

@NgModule({
  providers: [DatePipe],
  declarations: [
    AutofocusDirective,
    SelectorStoreDirective,
    AgGridCellSelectorComponent,
    AgGridCellLinkComponent,
    AgGridCellDatePickerComponent,
    AgGridCheckboxComponent,
    AgreementNamePipe,
    SelectorFormControlComponent,
    SearchStoreInputComponent,
    SectionTitleComponent,
  ],
  imports: [
    ...modules,
    HotToastModule.forRoot({}),
    EffectsNgModule.forRoot([]),
    RouterModule,
    AgGridModule,
  ],
  exports: [
    ...modules,
    AutofocusDirective,
    SelectorStoreDirective,
    HotToastModule,
    AgGridModule,
    AgGridCellSelectorComponent,
    AgGridCellLinkComponent,
    AgGridCheckboxComponent,
    AgreementNamePipe,
    SelectorFormControlComponent,
    SearchStoreInputComponent,
    SectionTitleComponent,
  ],
})
export class SharedModule {}
