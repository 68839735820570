import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'country';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка стран',
  successMessage: 'Список стран',
  failMessage: 'Ошибка при загрузке стран',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка страны',
  successMessage: 'Страна загружена',
  failMessage: 'Ошибка при загрузке страны',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание страны',
  successMessage: 'Страна создана',
  failMessage: 'Ошибка при создании страны',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление страны',
  successMessage: 'Страна обновлена',
  failMessage: 'Ошибка при обновлении страны',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление страны',
  successMessage: 'Страна удалена',
  failMessage: 'Ошибка при удалении страны',
});
