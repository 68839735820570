import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface AgGridCellLinkParams {
  routerLink: (params: ICellRendererParams) => string;
  queryParams?: (params: ICellRendererParams) => object | undefined;
  state?: (params: ICellRendererParams) => object | undefined;
  text?: (params: ICellRendererParams) => string;
}

@Component({
  template: `<a
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    [state]="state"
    >{{ text }}</a
  >`,
})
export class AgGridCellLinkComponent implements AgRendererComponent {
  params!: ICellRendererParams;
  routerLink = '';
  queryParams: any;
  state: any;
  text = '';

  agInit(params: ICellRendererParams & AgGridCellLinkParams): void {
    this.params = params;
    this.routerLink = params.routerLink(params);
    this.queryParams = params.queryParams?.(params);
    this.state = params.state?.(params);
    this.text = params.text ? params.text(params) : params.value;
  }

  refresh(): boolean {
    return false;
  }
}
