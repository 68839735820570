import { Injectable } from '@angular/core';
import { Prisma, Quantity } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

@Injectable({ providedIn: 'root' })
export class QuantityService extends CRUDService<Quantity> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'quantity',
      plural: 'quantities',
      list: `
        ${Prisma.QuantityScalarFieldEnum.id}
        ${Prisma.QuantityScalarFieldEnum.name}
        ${Prisma.QuantityScalarFieldEnum.symbol}
      `,
    });
  }
}
