import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngneat/effects';
import { CreateHotToastRef, HotToastService } from '@ngneat/hot-toast';
import { catchError, finalize, map, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { login } from './auth.actions';
import { AuthRepository } from './auth.repository';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  constructor(
    private authRepository: AuthRepository,
    private authService: AuthService,
    private toast: HotToastService
  ) {}

  login$ = createEffect(
    (actions) => {
      let toaster: CreateHotToastRef<unknown>;
      return actions.pipe(
        ofType(login.action),
        switchMap((payload) => {
          environment?.production ||
            (toaster = this.toast.loading(login.requestMessage()));
          this.authRepository.updateStatus('pending');
          return this.authService.login(payload.login, payload.password).pipe(
            map((response) => {
              if (!environment?.production) {
                this.toast.success(login.successMessage());
              }
              this.authRepository.updateTokenData(response?.data?.login);
              return login.successAction();
            }),
            catchError((error) => {
              if (!environment?.production) {
                this.toast.error(login.failMessage());
              }
              this.authRepository.updateStatus('idle');
              return of(login.failAction({ error: error.message }));
            }),
            finalize(() => {
              toaster?.close();
            })
          );
        })
      );
    },
    { dispatch: true }
  );
}
