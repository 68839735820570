import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'materialProperty';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка показателей',
  successMessage: 'Список показателей загружен',
  failMessage: 'Ошибка при загрузке списка показателей',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка показателя',
  successMessage: 'Показатель загружен',
  failMessage: 'Ошибка при загрузке показателя',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание показателя',
  successMessage: 'Показатель создан',
  failMessage: 'Ошибка при создании показателя',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление показателя',
  successMessage: 'Показатель обновлен',
  failMessage: 'Ошибка при обновлении показателя',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление показателя',
  successMessage: 'Показатель удален',
  failMessage: 'Ошибка при удалении показателя',
});
