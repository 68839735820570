import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'person';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка лиц',
  successMessage: 'Список лиц',
  failMessage: 'Ошибка при загрузке списка лиц',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка лица',
  successMessage: 'Лицо загружен',
  failMessage: 'Ошибка при загрузке лица',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание лица',
  successMessage: 'Лицо создан',
  failMessage: 'Ошибка при создании лица',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление лица',
  successMessage: 'Лицо обновлено',
  failMessage: 'Ошибка при обновлении лица',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление лица',
  successMessage: 'Лицо удалено',
  failMessage: 'Ошибка при удалении лица',
});
