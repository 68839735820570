import { Component, HostListener } from '@angular/core';
import { DevService } from './dev/dev.service';

@Component({
  selector: 'tl-root',
  template: `<router-outlet name="dev"></router-outlet>
    <router-outlet></router-outlet>`,
})
export class AppComponent {
  constructor(private readonly devService: DevService) {}

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.devService.toggleDevMode(event);
  }
}
