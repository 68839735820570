import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Application } from '@prisma/client';
import { CRUDRepository } from '../../shared/crud/repository';
import { loadList } from './application.actions';
import { ApplicationListItemGQL } from './application.interface';

const { indicatorEntitiesRef, withIndicatorEntities } =
  entitiesPropsFactory('indicator');

interface IndicatorItem {
  id: string;
  applicationId: Application['id'];
  quantity: number;
}

type StatusPaths = 'list' | 'item';

const { state, config } = createState(
  withEntities<Application>(),
  withActiveId(),
  withIndicatorEntities<IndicatorItem>(),
  withRequestsStatus<StatusPaths>()
);

export const store = new Store({ name: 'applications', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class ApplicationRepository extends CRUDRepository<
  Application,
  typeof store
> {
  constructor() {
    super(store, { loadListAction: loadList });
  }

  public override searchEntity(
    entity: ApplicationListItemGQL,
    searchText: string
  ) {
    searchText = searchText.toLowerCase();
    if (entity.number) {
      if (entity.number.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.client?.name) {
      if (entity.client.name.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.onBehalf?.name) {
      if (entity.onBehalf?.name?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    return false;
  }
}
