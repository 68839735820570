import { IChange, Operation } from 'json-diff-ts';
import { isTempId } from './random-id-generator';

export function changesToGraphQL(changes: IChange[], settable: boolean) {
  let result = changes.reduce((prev, curr) => {
    if ('value' in curr) {
      if (curr.type !== Operation.REMOVE) {
        if (settable) {
          prev[curr.key] = { set: curr.value };
        } else {
          prev[curr.key] = curr.value;
        }
      }
    }
    return prev;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);

  // Изменения в массивах
  result = changes.reduce((prev, curr) => {
    if ('changes' in curr && curr.changes?.length) {
      prev[curr.key] = curr.changes?.reduce((p, c) => {
        if (c.type === Operation.ADD) {
          p['createMany'] = p['createMany'] || {};
          p['createMany']['data'] = p['createMany']['data'] || [];
          const value = c.value;
          if (isTempId(value?.id)) {
            value.id = undefined;
          }
          p['createMany']['data'].push(valuesWithArrayToGraphQLSettable(value));
          return p;
        }
        if (c.type === Operation.UPDATE) {
          p['updateMany'] = p['updateMany'] || [];
          p['updateMany'].push({
            where: { id: { equals: c.key } },
            data: changesToGraphQL(c.changes || [], settable),
          });
          return p;
        }
        if (c.type === Operation.REMOVE) {
          p['deleteMany'] = p['deleteMany'] || {};
          p['deleteMany']['id'] = p['deleteMany']['id'] || {};
          p['deleteMany']['id']['in'] = p['deleteMany']['id']['in'] || [];
          p['deleteMany']['id']['in'].push(c.key);
          return p;
        }
      }, prev[curr.key] || {});
    }
    return prev;
  }, result);

  return result;
}

function valuesWithArrayToGraphQLSettable(obj: Record<string, any>) {
  const keys = Object.keys(obj);
  return keys.reduce((prev, curr) => {
    if (Array.isArray(obj[curr])) {
      prev[curr] = { set: obj[curr] };
    } else {
      prev[curr] = obj[curr];
    }
    return prev;
  }, {} as Record<string, any>);
}
