import { NgModule } from '@angular/core';
import {
  AimOutline,
  AuditOutline,
  CloseCircleOutline,
  CopyOutline,
  DashboardOutline,
  DeleteOutline,
  EditOutline,
  ExperimentOutline,
  FormOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  PlusOutline,
  SaveOutline,
  InfoCircleTwoTone,
  FileTextOutline,
  SyncOutline,
  CarryOutOutline,
  GoldOutline,
  PercentageOutline,
  FieldBinaryOutline,
  ShakeOutline,
  InfoCircleOutline,
  PlusCircleOutline,
  GlobalOutline,
  UserOutline,
  BookOutline,
  FileWordTwoTone,
  SearchOutline,
} from '@ant-design/icons-angular/icons';
import { NzIconModule, NZ_ICONS } from 'ng-zorro-antd/icon';

const icons = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  PlusOutline,
  ExperimentOutline,
  AuditOutline,
  AimOutline,
  EditOutline,
  SaveOutline,
  CopyOutline,
  CloseCircleOutline,
  DeleteOutline,
  InfoCircleTwoTone,
  FileTextOutline,
  SyncOutline,
  CarryOutOutline,
  GoldOutline,
  PercentageOutline,
  FieldBinaryOutline,
  ShakeOutline,
  InfoCircleOutline,
  PlusCircleOutline,
  GlobalOutline,
  UserOutline,
  BookOutline,
  FileWordTwoTone,
  SearchOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
