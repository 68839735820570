import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'agreement';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка договоров',
  successMessage: 'Договоры загружены',
  failMessage: 'Ошибка при загрузке договоров',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка договора',
  successMessage: 'Договор загружен',
  failMessage: 'Ошибка при загрузке договора',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание нового договора',
  successMessage: 'Новый договор создан',
  failMessage: 'Ошибка при создании нового договора',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление договора',
  successMessage: 'Договор обновлен',
  failMessage: 'Ошибка при обновление договора',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление договора',
  successMessage: 'Договор удален',
  failMessage: 'Ошибка при удалении договора',
});
