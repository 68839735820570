import {
  ActionCreatorsAreNotAllowed,
  actionsFactory,
  props,
} from '@ngneat/effects';
import { Action } from '@ngneat/effects/src/lib/actions.types';
import { IChange } from 'json-diff-ts';

const capitalizeFirstLetter = (
  [first, ...rest]: string,
  locale = navigator.language
) => first.toLocaleUpperCase(locale) + rest.join('');

export function loadListActions<T>(params: {
  entityName: string;
  factory: ReturnType<typeof actionsFactory>;
  actionMessage: string;
  successMessage: string;
  failMessage: string;
}) {
  const entityName = capitalizeFirstLetter(params.entityName);
  return {
    action: params.factory.create(`Load ${entityName} List`),
    actionMessage: () => params.actionMessage,

    successAction: params.factory.create(
      `Load ${entityName} List Success`,
      props<{ entities: T[] }>()
    ),
    successMessage: (data: unknown[]) =>
      `${params.successMessage}.<br>Элементов: ${
        Array.isArray(data) ? data.length : 1
      }`,

    failAction: params.factory.create(
      `Load ${entityName} List Fail`,
      props<{ error: unknown }>()
    ),
    failMessage: () => params.failMessage,
  };
}

export function loadItemActions<T>(params: {
  entityName: string;
  factory: ReturnType<typeof actionsFactory>;
  actionMessage: string;
  successMessage: string;
  failMessage: string;
}) {
  const entityName = capitalizeFirstLetter(params.entityName);
  return {
    action: params.factory.create(`Load ${entityName} Item`),
    actionMessage: () => params.actionMessage,

    successAction: params.factory.create(
      `Load ${entityName} Item Success`,
      props<{ entity: T }>()
    ),
    successMessage: () => params.successMessage,

    failAction: params.factory.create(
      `Load ${entityName} Item Fail`,
      props<{ error: unknown }>()
    ),
    failMessage: () => params.failMessage,
  };
}

export function createItemActions<T>(params: {
  entityName: string;
  factory: ReturnType<typeof actionsFactory>;
  actionMessage: string;
  successMessage: string;
  failMessage: string;
}) {
  const entityName = capitalizeFirstLetter(params.entityName);
  return {
    action: params.factory.create(
      `Create ${entityName} Item`,
      props<{ entity: T }>()
    ),
    actionMessage: () => params.actionMessage,

    successAction: params.factory.create(
      `Create ${entityName} Item Success`,
      props<{ entity: T }>()
    ),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    successMessage: (entity: T) => params.successMessage,

    failAction: params.factory.create(
      `Create ${entityName} Item Fail`,
      props<{ error: unknown }>()
    ),
    failMessage: () => params.failMessage,
  };
}

export function updateItemActions<
  T extends { [P in IdKey]: string },
  IdKey extends string = 'id'
>(params: {
  entityName: string;
  factory: ReturnType<typeof actionsFactory>;
  actionMessage: string;
  successMessage: string;
  failMessage: string;
}) {
  const entityName = capitalizeFirstLetter(params.entityName);
  return {
    action: params.factory.create(
      `Update ${entityName} Item`,
      props<{
        id: T[IdKey];
        changes: IChange[];
      }>()
    ),
    actionMessage: () => params.actionMessage,

    successAction: params.factory.create(
      `Update ${entityName} Item Success`,
      props<{ id: T[IdKey]; entity: T }>()
    ),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    successMessage: (entity: T) => params.successMessage,

    failAction: params.factory.create(
      `Update ${entityName} Item Fail`,
      props<{ error: unknown }>()
    ),
    failMessage: () => params.failMessage,
  };
}

export function deleteItemActions<
  T extends { [P in IdKey]: string },
  IdKey extends string = 'id'
>(params: {
  entityName: string;
  factory: ReturnType<typeof actionsFactory>;
  actionMessage: string;
  successMessage: string;
  failMessage: string;
}) {
  const entityName = capitalizeFirstLetter(params.entityName);
  return {
    action: params.factory.create(
      `Delete ${entityName} Item`,
      props<{
        id: T[IdKey];
      }>()
    ),
    actionMessage: () => params.actionMessage,

    successAction: params.factory.create(
      `Delete ${entityName} Item Success`,
      props<{ entity: T }>()
    ),
    successMessage: () => params.successMessage,

    failAction: params.factory.create(
      `Delete ${entityName} Item Fail`,
      props<{ error: unknown }>()
    ),
    failMessage: () => params.failMessage,
  };
}
