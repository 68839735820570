import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'uom';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка единиц измерения',
  successMessage: 'Список единиц измерения',
  failMessage: 'Ошибка при загрузке единиц измерения',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка единицы измерения',
  successMessage: 'Единица измерения загружена',
  failMessage: 'Ошибка при загрузке единицы измерения',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание единицы измерения',
  successMessage: 'Единица измерения создана',
  failMessage: 'Ошибка при создании единицы измерения',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление единицы измерения',
  successMessage: 'Единица измерения обновлена',
  failMessage: 'Ошибка при обновлении единицы измерения',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление единицы измерения',
  successMessage: 'Единица измерения удалена',
  failMessage: 'Ошибка при удалении единицы измерения',
});
