import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { getOperationAST } from 'graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { environment } from '../../environments/environment';
import { errorsOf } from '../shared/error.util';
import { AuthRepository } from '../auth/state/auth.repository';
import { hasAuthError } from '../auth/state/auth.service';

const uri = environment.api_endpoint; // <-- add the URL of the GraphQL server here

function createApolloOptions(
  httpLink: HttpLink,
  repository: AuthRepository,
  router: Router
) {
  let needReconnect = !repository.access_token();

  const httpContext = setContext(() => {
    return {
      headers: {
        Authorization: `Bearer ${repository.access_token()}`,
      },
    };
  });

  const http = ApolloLink.from([httpContext, httpLink.create({ uri: uri })]);

  const subClient = new SubscriptionClient(uri.replace('http', 'ws'), {
    connectionParams: () => {
      const result = {
        Authorization: `Bearer ${repository.access_token()}`,
      };
      return result;
    },
    reconnect: true,
  });

  subClient.onConnected(() => console.log('websocket: connected'));
  subClient.onConnecting(() => console.log('websocket: connecting'));
  subClient.onDisconnected(() => console.log('websocket: disconnected'));
  subClient.onError(() => console.log('websocket: error'));
  subClient.onReconnected(() => console.log('websocket: reconnected'));
  subClient.onReconnecting(() => console.log('websocket: reconnecting'));

  const ws = new WebSocketLink(subClient);

  repository.loggedIn$.subscribe({
    next: () => {
      console.log('reconnect decision', needReconnect);
      if (needReconnect) {
        subClient.close(true, true);
      }
    },
  });

  const splitLink = ApolloLink.split(
    (operation) => {
      const operationAST = getOperationAST(
        operation.query,
        operation.operationName
      );
      return !!operationAST && operationAST.operation === 'subscription';
    },
    ws,
    http
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let relogin = false;
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
        console.error(`Message`, message);
      });
      if (hasAuthError(graphQLErrors)) {
        needReconnect = true;
        relogin = true;
      }
    }
    if (networkError) console.log(`[Network error]: ${errorsOf(networkError)}`);
    if (relogin) {
      if (repository.refreshingToken() !== 'initial') {
        router.navigateByUrl('login');
      }
    }
  });

  const link = errorLink.concat(splitLink);

  // create Apollo
  return {
    link: link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloOptions,
      deps: [HttpLink, AuthRepository, Router],
    },
  ],
})
export class GraphQLModule {}
