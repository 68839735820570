import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { of, Subject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthRepository, RefreshingTokenType } from './auth.repository';

export const hasAuthError = (errors?: ReadonlyArray<{ message: string }>) => {
  let result = false;
  errors?.forEach((err) => {
    const message = err?.message;
    if (message) {
      result = ['Invalid refresh token', 'Unauthorized'].indexOf(message) > -1;
    }
  });
  return result;
};

export const isInvalidRefreshToken = (
  errors?: ReadonlyArray<{ message: string }>
) => {
  let result = false;
  errors?.forEach((err) => {
    const message = err?.message;
    if (message) {
      result = ['Invalid refresh token'].indexOf(message) > -1;
    }
  });
  return result;
};

export const isInvalidAccessToken = (
  errors?: ReadonlyArray<{ message: string }>
) => {
  let result = false;
  errors?.forEach((err) => {
    const message = err?.message;
    if (message) {
      result = ['Invalid access token'].indexOf(message) > -1;
    }
  });
  return result;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loginSubject = new Subject();
  private logoutSubject = new Subject();

  constructor(private repository: AuthRepository, private apollo: Apollo) {
    this.subscribeOnLogin();
    this.subscribeOnLogout();
  }

  private subscribeOnLogin() {
    // this.loginSubject
    //   .pipe(
    //     switchMap(() =>
    //       this.whoAmI().pipe(
    //         concatMap(() => this.appService.listenRobotStatus())
    //       )
    //     ),
    //     catchError(err => of())
    //   )
    //   .subscribe();
  }

  private subscribeOnLogout() {
    this.logoutSubject
      .pipe(
        tap(() => {
          this.repository.clearTokens();
          localStorage.removeItem('company_short_name');
          localStorage.removeItem('display_name');
          this.repository.reset();
        })
      )
      .subscribe();
  }

  login(login: string, password: string) {
    return this.apollo
      .mutate<{ login: { refresh_token: string; access_token: string } }>({
        mutation: gql`
          mutation {
            login(login: "${login}", password: "${password}") {
              access_token
              refresh_token
            }
          }`,
      })
      .pipe(
        tap(() => {
          this.loginSubject.next(true);
        })
      );
  }

  logout() {
    this.logoutSubject.next(true);
  }

  loginRequired() {
    return !localStorage.getItem('refresh_token');
  }

  refreshToken(token: string, type: Exclude<RefreshingTokenType, false>) {
    this.repository.startRefreshToken(type);
    return this.apollo
      .mutate<{
        refreshToken?: { refresh_token: string; access_token: string };
      }>({
        mutation: gql(
          `
          mutation {
            refreshToken(token: "${token}") {
              access_token
              refresh_token
            }
          }`
        ),
      })
      .pipe(
        tap((res) => {
          this.repository.updateTokenData(res?.data?.refreshToken);
        }),
        finalize(() => this.repository.endRefreshToken())
      );
  }

  whoAmI() {
    return this.apollo
      .query<{
        whoAmI: {
          login: string;
          display: string;
          company: { shortName: string };
        };
      }>({
        query: gql(`
          {
            whoAmI {
              login
              display
              company {
                shortName
              }
            }
          }`),
      })
      .pipe(
        tap((res) => {
          localStorage.setItem(
            'display_name',
            res.data.whoAmI.display || res.data.whoAmI.login
          );
          localStorage.setItem(
            'company_short_name',
            res.data.whoAmI.company.shortName
          );
          this.repository.update({
            displayName: res.data.whoAmI.display || res.data.whoAmI.login,
            companyName: res.data.whoAmI.company.shortName,
          });
        }),
        catchError((err) => {
          console.error(err);
          return of();
        })
      );
  }
}
