import { Injectable } from '@angular/core';
import { Application, Prisma } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

const listProperties = `
  ${Prisma.ApplicationScalarFieldEnum.id}
  ${Prisma.ApplicationScalarFieldEnum.date}
  ${Prisma.ApplicationScalarFieldEnum.number}
  ${Prisma.ApplicationScalarFieldEnum.clientId}
  client {
    ${Prisma.PersonScalarFieldEnum.name}
  }
  ${Prisma.ApplicationScalarFieldEnum.onBehalfId}
  onBehalf {
    ${Prisma.PersonScalarFieldEnum.name}
  }
`;

const entityProperies =
  listProperties +
  `
  ${Prisma.ApplicationScalarFieldEnum.agreementId}
  agreement {
    ${Prisma.AgreementScalarFieldEnum.number}
    ${Prisma.AgreementScalarFieldEnum.signDate}
  }
  ${Prisma.ApplicationScalarFieldEnum.productProducerId}
  productProducer {
    ${Prisma.PersonScalarFieldEnum.name}
  }
  ${Prisma.ApplicationScalarFieldEnum.productName}
  ${Prisma.ApplicationScalarFieldEnum.productManufactureDate}
  ${Prisma.ApplicationScalarFieldEnum.productDescription}
  ${Prisma.ApplicationScalarFieldEnum.sampleActNumber}
  ${Prisma.ApplicationScalarFieldEnum.sampleActDate}
  ${Prisma.ApplicationScalarFieldEnum.sampleCount}
  ${Prisma.ApplicationScalarFieldEnum.sampleUOMId}
  sampleUOM {
    ${Prisma.UnitOfMeasurementScalarFieldEnum.name}
  }
  ${Prisma.ApplicationScalarFieldEnum.sampleLocation}
  ${Prisma.ApplicationScalarFieldEnum.sampleSubmittedById}
  sampleSubmittedBy {
    ${Prisma.PersonScalarFieldEnum.name}
  }
  ${Prisma.ApplicationScalarFieldEnum.sampleReceivedById}
  sampleReceivedBy {
    ${Prisma.PersonScalarFieldEnum.name}
  }
  ${Prisma.ApplicationScalarFieldEnum.technicalDocumentId}
  technicalDocument {
    ${Prisma.TechnicalDocumentScalarFieldEnum.name}
  }
  ${Prisma.ApplicationScalarFieldEnum.requirementsDescription}
  ${Prisma.ApplicationScalarFieldEnum.testingRequirementsDescription}
  ${Prisma.ApplicationScalarFieldEnum.extraInfo}
`;

@Injectable({ providedIn: 'root' })
export class ApplicationService extends CRUDService<Application> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'application',
      plural: 'applications',
      list: listProperties,
      entity: entityProperies,
    });
  }
}
