import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthRepository } from './state/auth.repository';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private repository: AuthRepository) {}

  canActivate(): boolean {
    if (this.repository.isLoggedIn()) {
      return true;
    }

    this.router.navigateByUrl('login');
    return false;
  }
}
