import { Injectable } from '@angular/core';
import { Person, Prisma } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

const listProperties = `
  ${Prisma.PersonScalarFieldEnum.id}
  ${Prisma.PersonScalarFieldEnum.name}
  ${Prisma.PersonScalarFieldEnum.type}
  ${Prisma.PersonScalarFieldEnum.taxID}
  ${Prisma.PersonScalarFieldEnum.ogrn}
`;

const entityProperies =
  listProperties +
  `
  ${Prisma.PersonScalarFieldEnum.firstName}
  ${Prisma.PersonScalarFieldEnum.middleName}
  ${Prisma.PersonScalarFieldEnum.lastName}
  ${Prisma.PersonScalarFieldEnum.shortName}
  ${Prisma.PersonScalarFieldEnum.longName}
  ${Prisma.PersonScalarFieldEnum.phone}
  ${Prisma.PersonScalarFieldEnum.email}
  addresses {
    ${Prisma.PersonAddressScalarFieldEnum.id}
    ${Prisma.PersonAddressScalarFieldEnum.type}
    ${Prisma.PersonAddressScalarFieldEnum.countryId}
    country {
      ${Prisma.CountryScalarFieldEnum.name}
    }
    ${Prisma.PersonAddressScalarFieldEnum.address}
  }
  agreements {
    ${Prisma.AgreementScalarFieldEnum.id}
    ${Prisma.AgreementScalarFieldEnum.number}
    ${Prisma.AgreementScalarFieldEnum.signDate}
  }
  licenses {
    ${Prisma.LicenseScalarFieldEnum.id}
    ${Prisma.LicenseScalarFieldEnum.number}
    ${Prisma.LicenseScalarFieldEnum.issueDate}
  }
`;

@Injectable({ providedIn: 'root' })
export class PersonService extends CRUDService<Person> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'person',
      plural: 'persons',
      list: listProperties,
      entity: entityProperies,
    });
  }
}
