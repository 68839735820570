import { actionsFactory } from '@ngneat/effects';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'technical document';
export const actions = actionsFactory(entityName + 's');

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка технических документов',
  successMessage: 'Список технических документов загружен',
  failMessage: 'Ошибка при загрузке списка технических документов',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка технического документа',
  successMessage: 'Технический документ загружен',
  failMessage: 'Ошибка при загрузке технического документа',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание технического документа',
  successMessage: 'Технический документ создан',
  failMessage: 'Ошибка при создании технического документа',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление технического документа',
  successMessage: 'Технический документ обновлен',
  failMessage: 'Ошибка при обновлении технического документа',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление технического документа',
  successMessage: 'Технический документ удален',
  failMessage: 'Ошибка при удалении технического документа',
});
