import { createEffect, ofType } from '@ngneat/effects';
import { StatusState } from '@ngneat/elf-requests';
import { CreateHotToastRef, HotToastService } from '@ngneat/hot-toast';
import { IChange } from 'json-diff-ts';
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  createItemActions,
  deleteItemActions,
  loadListActions,
  updateItemActions,
} from './actions';

export function loadListEffect<T>(params: {
  action: ReturnType<typeof loadListActions>;
  serviceAction: () => Observable<T[]>;
  toast: HotToastService;
  repository: {
    updateStatus(
      path: string,
      status: Exclude<StatusState['value'], 'error'>
    ): void;
    updateStatusError(path: string, error: unknown): void;
    setEntities(entities: T[]): void;
  };
}) {
  return createEffect(
    (actions) => {
      let toaster: CreateHotToastRef<unknown>;
      return actions.pipe(
        ofType(params.action.action),
        exhaustMap(() => {
          environment?.production ||
            (toaster = params.toast.loading(params.action.actionMessage()));
          params.repository.updateStatus('list', 'pending');
          return params.serviceAction().pipe(
            map((entities) => {
              if (!environment?.production) {
                params.toast.success(params.action.successMessage(entities));
              }
              params.repository.setEntities(entities);
              return params.action.successAction({ entities: entities });
            }),
            catchError((error) => {
              if (!environment?.production) {
                params.toast.error(params.action.failMessage());
              }
              params.repository.updateStatusError(
                'list',
                error.message || error
              );
              return of(params.action.failAction({ error: error.message }));
            }),
            finalize(() => {
              toaster?.close();
            })
          );
        })
      );
    },
    { dispatch: true }
  );
}

export function createItemEffect<T>(params: {
  action: ReturnType<typeof createItemActions>;
  serviceAction: (entity: T) => Observable<T>;
  toast: HotToastService;
  repository: {
    updateStatus(
      path: string,
      status: Exclude<StatusState['value'], 'error'>
    ): void;
    updateStatusError(path: string, error: unknown): void;
    addEntity(entity: T): void;
  };
}) {
  return createEffect(
    (actions) => {
      let toaster: CreateHotToastRef<unknown>;
      return actions.pipe(
        ofType(params.action.action),
        switchMap((payload) => {
          params.repository.updateStatus('item', 'pending');
          environment?.production ||
            (toaster = params.toast.loading(params.action.actionMessage()));
          return params.serviceAction(payload.entity as T).pipe(
            map((entity) => {
              if (!environment?.production) {
                params.toast.success(params.action.successMessage(entity));
              }
              params.repository.addEntity(entity);
              return params.action.successAction({ entity: entity });
            }),
            catchError((error) => {
              if (!environment?.production) {
                params.toast.error(params.action.failMessage());
              }
              params.repository.updateStatusError(
                'item',
                error.message || error
              );
              return of(params.action.failAction({ error: error.message }));
            }),
            finalize(() => {
              toaster?.close();
            })
          );
        })
      );
    },
    { dispatch: true }
  );
}

export function updateItemEffect<
  T extends { [P in IdKey]: string },
  IdKey extends string = 'id'
>(params: {
  action: ReturnType<typeof updateItemActions>;
  serviceAction: (id: string, changes: IChange[]) => Observable<T>;
  toast: HotToastService;
  repository: {
    updateStatus(
      path: string,
      status: Exclude<StatusState['value'], 'error'>
    ): void;
    updateStatusError(path: string, error: unknown): void;
    updateEntity(id: T[IdKey], entity: T): void;
  };
}) {
  return createEffect(
    (actions) => {
      let toaster: CreateHotToastRef<unknown>;
      return actions.pipe(
        ofType(params.action.action),
        switchMap((payload) => {
          params.repository.updateStatus('item', 'pending');
          environment?.production ||
            (toaster = params.toast.loading(params.action.actionMessage()));
          // return this.service.update(data.id, data.entity).pipe(
          return params.serviceAction(payload.id, payload.changes).pipe(
            map((entity) => {
              if (!environment?.production) {
                params.toast.success(params.action.successMessage(entity));
              }
              params.repository.updateEntity(payload.id, entity);
              return params.action.successAction({
                id: payload.id,
                entity: entity,
              });
            }),
            catchError((error) => {
              if (!environment?.production) {
                params.toast.error(params.action.failMessage());
              }
              params.repository.updateStatusError(
                'item',
                error.message || error
              );
              return of(params.action.failAction({ error: error.message }));
            }),
            finalize(() => {
              toaster?.close();
            })
          );
        })
      );
    },
    { dispatch: true }
  );
}

export function deleteItemEffect<
  T extends { [P in IdKey]: string },
  IdKey extends string = 'id'
>(params: {
  idKey?: string;
  action: ReturnType<typeof deleteItemActions>;
  serviceAction: (id: string) => Observable<T>;
  toast: HotToastService;
  repository: {
    updateStatus(
      path: string,
      status: Exclude<StatusState['value'], 'error'>
    ): void;
    updateStatusError(path: string, error: unknown): void;
    deleteEntity(id: T[IdKey]): void;
  };
}) {
  return createEffect(
    (actions) => {
      let toaster: CreateHotToastRef<unknown>;
      return actions.pipe(
        ofType(params.action.action),
        switchMap((payload) => {
          params.repository.updateStatus('item', 'pending');
          environment?.production ||
            (toaster = params.toast.loading(params.action.actionMessage()));
          return params.serviceAction(payload.id).pipe(
            map((entity) => {
              if (!environment?.production) {
                params.toast.success(params.action.successMessage());
              }
              const idKey = params.idKey || 'id';
              params.repository.deleteEntity((payload as never)[idKey]);
              return params.action.successAction({ entity: entity as T });
            }),
            catchError((error) => {
              if (!environment?.production) {
                params.toast.error(params.action.failMessage());
              }
              params.repository.updateStatusError(
                'item',
                error.message || error
              );
              return of(params.action.failAction({ error: error.message }));
            }),
            finalize(() => {
              toaster?.close();
            })
          );
        })
      );
    },
    { dispatch: true }
  );
}
