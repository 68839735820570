<div nz-row nzJustify="center" style="margin: 0">
  <img
    src="/assets/login.jpg"
    width="400"
    height="302"
    style="margin-top: 30px"
  />
</div>
<div nz-row nzJustify="center" style="margin: 0 5%">
  <form
    nz-form
    [formGroup]="form"
    (ngSubmit)="login()"
    style="margin-top: 30px"
  >
    <div style="margin-bottom: 10px">
      <nz-form-control nzErrorTip="Укажите адрес электронной почты">
        <nz-form-label nzFor="login" style="width: 60px">Почта</nz-form-label>
        <input nz-input formControlName="login" id="login" autofocus />
      </nz-form-control>
    </div>
    <div>
      <nz-form-control nzErrorTip="Укажите пароль">
        <nz-form-label nzFor="password" style="width: 60px"
          >Пароль</nz-form-label
        >
        <input
          nz-input
          formControlName="password"
          id="password"
          type="password"
        />
      </nz-form-control>
    </div>
    <div style="text-align: center; margin: 20px 5% 0 5%">
      <button nz-button nzType="default" (click)="login()" type="submit">
        Вход
      </button>
    </div>
  </form>
</div>

<div *ngIf="!!error" nz-row nzJustify="center" style="margin: 30px 5%">
  <nz-alert
    nz-col
    nzType="error"
    nzMessage="Ошибка аутентификации"
    nzDescription="При входе в систему возникла ошибка. Проверьте, что электронная почта и пароль указаны верно."
    nzShowIcon
    style="max-width: 500px"
  ></nz-alert>
</div>
