import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { CRUDRepository } from '../../crud/repository';

@UntilDestroy()
@Component({
  selector: 'tl-search-store-input',
  template: `
    <div class="search-input-wrapper">
      <nz-input-group [nzPrefix]="prefixTemplateUser">
        <input
          [formControl]="searchControl"
          type="text"
          nz-input
          placeholder="Поиск"
          #filterInput
        />
      </nz-input-group>
    </div>
    <ng-template #prefixTemplateUser
      ><i nz-icon nzType="search" class="search-icon"></i
    ></ng-template>
  `,
})
export class SearchStoreInputComponent implements OnInit {
  @Input() repository!: CRUDRepository;
  @Input() autofocus: boolean | '' = false;
  @Input() selectOnEnter = true;
  @Input() grid?: AgGridAngular;

  @ViewChild('filterInput') filterInput!: ElementRef;

  public searchControl = new FormControl();
  public selectedEntity: any;
  public selectedText?: string;
  public aboutToSearch = false;
  public prevSearchText = '';
  public selectedRowIndex = -1;

  @HostListener('keydown.enter', ['$event'])
  onEnter() {
    if (!this.selectOnEnter) return;
    if (!this.selectedEntity) return;

    const linkData = this.repository.linkData(this.selectedEntity);
    this.router.navigate([linkData.link], {
      relativeTo: this.route,
      state: linkData.state,
    });
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onArrowDown(event: KeyboardEvent) {
    if (this.grid?.api.getDisplayedRowAtIndex(this.selectedRowIndex + 1)) {
      this.selectedRowIndex++;
      this.updateSelectedRow();
    }
    event.preventDefault();
  }

  @HostListener('keydown.arrowup', ['$event'])
  onArrowUp(event: KeyboardEvent) {
    if (this.selectedRowIndex > 0) {
      this.selectedRowIndex--;
      this.updateSelectedRow();
    }
    event.preventDefault();
  }

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  private searchText(value: string) {
    return value ? value.trim() : '';
  }

  ngOnInit(): void {
    this.repository.searchText$.next('');

    this.searchControl.valueChanges
      .pipe(
        map((value) => this.searchText(value)),
        distinctUntilChanged(),
        tap((value) => {
          if (!this.prevSearchText && value) {
            this.aboutToSearch = true;
          }
          this.prevSearchText = value;
        }),
        debounceTime(200),
        tap(() => (this.aboutToSearch = false)),
        distinctUntilChanged(),
        filter(() => !!this.repository),
        untilDestroyed(this)
      )
      .subscribe({
        next: (value) => {
          if (!value) {
            this.selectedRowIndex = -1;
          } else {
            this.selectedRowIndex = 0;
          }
          this.repository.searchText$.next(value);
        },
      });

    this.repository.searchEntities$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          setTimeout(() => {
            this.updateSelectedRow();
          }, 50);
        })
      )
      .subscribe();

    setTimeout(() => {
      if (this.autofocus || this.autofocus === '') {
        this.filterInput?.nativeElement?.focus();
      }
    }, 100);
  }

  public updateSelectedRow() {
    const node = this.grid?.api.getDisplayedRowAtIndex(this.selectedRowIndex);
    if (node && !this.aboutToSearch) {
      this.selectedEntity = node.data;
      this.selectedText = this.repository.lookupLabel(this.selectedEntity);
      node.setSelected(true, true);
    } else {
      this.selectedEntity = undefined;
      this.selectedText = '';
    }
  }
}
