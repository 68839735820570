import { actionsFactory, props } from '@ngneat/effects';

export const actions = actionsFactory('auth');

export const login = {
  action: actions.create('Login', props<{ login: string; password: string }>()),
  successAction: actions.create('Login Success'),
  failAction: actions.create('Login Fail', props<{ error: unknown }>()),
  requestMessage: () => `Аутентификация по имени и паролю`,
  successMessage: () => `Аутентификация успешно пройдена`,
  failMessage: () => `Аутентификация НЕ пройдена`,
};
