import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'application';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка заявок',
  successMessage: 'Список заявок загружен',
  failMessage: 'Ошибка при загрузке списка заявок',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка заявки',
  successMessage: 'Заявка загружена',
  failMessage: 'Ошибка при загрузке заявки',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание новой заявки',
  successMessage: 'Новая заявка создана',
  failMessage: 'Ошибка при создании новой заявки',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление заявки',
  successMessage: 'Заявка обновлена',
  failMessage: 'Ошибка при обновление заявки',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление заявки',
  successMessage: 'Заявка удалена',
  failMessage: 'Ошибка при удалении заявки',
});
