import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';

export type AgGridCheckboxParams = {
  readonly?: () => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  indeterminateValue?: any;
  setIndeterminate?: (params: ICellRendererParams) => void;
  getIndeterminate?: (params: ICellRendererParams) => boolean;
};

export const INDETERMINATE_VALUE = '~1fkWEl.xes';

@Component({
  selector: 'tl-ag-grid-checkbox',
  template: `<div
    nz-checkbox
    [nzChecked]="params.value"
    [nzIndeterminate]="indeterminate"
    #checkbox
  ></div>`,
  styles: [
    `
      :host {
        justify-content: center;
        text-align: center;
        display: block;
        width: 100%;
      }
      ::ng-deep .ant-checkbox-indeterminate .ant-checkbox-inner {
        border: 1px dashed silver;
        border-radius: 0;
        background-color: #f0f0f0;
      }
      ::ng-deep .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #f0f0f0;
      }
      div {
        height: 0;
      }
    `,
  ],
})
export class AgGridCheckboxComponent
  implements ICellRendererAngularComp, AfterViewInit
{
  public params!: ICellRendererParams & AgGridCheckboxParams;

  @ViewChild('checkbox')
  public checkbox!: NzCheckboxComponent;

  @ViewChild('checkbox', { read: ElementRef })
  public checkboxRef!: ElementRef<HTMLElement>;

  public indeterminate = false;

  constructor(private readonly renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.listen(
      this.checkbox.inputElement.nativeElement,
      'click',
      (event: MouseEvent) => {
        if (this.params.readonly?.()) {
          event.stopPropagation();
          event.preventDefault();
        } else {
          const column = this.params.column;
          if (column) {
            const colId = column.getColId();
            // this.params.node.getD
            const value = this.params.api.getValue(column, this.params.node);
            if (this.indeterminateMode()) {
              switch (value) {
                case false:
                  this.params.node.setDataValue(colId, true);
                  this.indeterminate = false;
                  break;
                case true:
                  if (this.params.setIndeterminate) {
                    this.params.setIndeterminate(this.params);
                  } else {
                    this.params.node.setDataValue(
                      colId,
                      this.params.indeterminateValue
                    );
                  }
                  this.indeterminate = true;
                  break;
                default:
                  this.params.node.setDataValue(colId, false);
                  this.indeterminate = false;
                  break;
              }
            } else {
              this.params.node.setDataValue(colId, !value);
            }
          }
        }
      }
    );
  }

  indeterminateMode() {
    return (
      !!this.params.setIndeterminate ||
      !!this.params.getIndeterminate ||
      'indeterminateValue' in this.params
    );
  }

  agInit(params: ICellRendererParams & AgGridCheckboxParams): void {
    this.params = params;
    if (this.params.getIndeterminate) {
      this.indeterminate = this.params.getIndeterminate(params);
    }
  }

  refresh() {
    return false;
  }
}
