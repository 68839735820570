<nz-layout
  class="app-layout"
  [ngClass]="{ hidden: (devService.devMode$ | async) === true }"
>
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="240px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzCollapsedWidth]="38"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
        <i
          class="trigger"
          nz-icon
          [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        ></i>
      </span>
      <h1 style="color: #155a0c">Test & Go</h1>
    </div>

    <ul
      nz-menu
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
      nzMode="inline"
    >
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="book"></i>
        <span><a routerLink="/applications">Заявки</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="user"></i>
        <span><a routerLink="/persons">Юридич. и физ. лица</a></span>
      </li>
      <nz-divider style="margin: 10px 0"></nz-divider>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="dashboard"></i>
        <span><a routerLink="/equipment">Оборудование</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="audit"></i>
        <span><a routerLink="/technical-documents">Документация</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="carry-out"></i>
        <span><a routerLink="/requirements">Требования</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="shake"></i>
        <span><a routerLink="/test-methods">Методы исследований</a></span>
      </li>
      <nz-divider style="margin: 10px 0"></nz-divider>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="aim"></i>
        <span><a routerLink="/material-properties">Показатели</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="field-binary"></i>
        <span><a routerLink="/quantities">Измеряемые величины</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="percentage"></i>
        <span><a routerLink="/uom">Единицы измерения</a></span>
      </li>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="gold"></i>
        <span><a routerLink="/substances">Вещества</a></span>
      </li>
      <nz-divider style="margin: 10px 0"></nz-divider>
      <li [nzPaddingLeft]="10" nz-menu-item nzMatchRouter>
        <i nz-icon nzType="global"></i>
        <span><a routerLink="/countries">Страны</a></span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <h2>Центр Тест Сервис</h2>
      </div>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>

<div style="padding: 0 0 10px 10px; position: absolute; bottom: 0; left: 0">
  <span style="font-size: 10px">ver. {{ version }}</span>
</div>
