import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/applications' },
  {
    path: 'login',
    component: AuthComponent,
  },
  {
    path: 'dev',
    outlet: 'dev',
    children: [
      {
        path: '',
        loadChildren: () => import('./dev/dev.module').then((m) => m.DevModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'equipment',
        loadChildren: () =>
          import('./equipment/equipment.module').then((m) => m.EquipmentModule),
      },
      {
        path: 'material-properties',
        loadChildren: () =>
          import('./material-property/material-property.module').then(
            (m) => m.MaterialPropertyModule
          ),
      },
      {
        path: 'requirements',
        loadChildren: () =>
          import('./requirement/requirement.module').then(
            (m) => m.RequirementModule
          ),
      },
      {
        path: 'substances',
        loadChildren: () =>
          import('./substance/substance.module').then((m) => m.SubstanceModule),
      },
      {
        path: 'quantities',
        loadChildren: () =>
          import('./quantity/quantity.module').then((m) => m.QuantityModule),
      },
      {
        path: 'technical-documents',
        loadChildren: () =>
          import('./technical-document/technical-document.module').then(
            (m) => m.TechnicalDocumentModule
          ),
      },
      {
        path: 'test-methods',
        loadChildren: () =>
          import('./test-method/test-method.module').then(
            (m) => m.TestMethodModule
          ),
      },
      {
        path: 'uom',
        loadChildren: () =>
          import('./unit-of-measurement/unit-of-measurement.module').then(
            (m) => m.UnitOfMeasurementModule
          ),
      },
      {
        path: 'countries',
        loadChildren: () =>
          import('./country/country.module').then((m) => m.CountryModule),
      },
      {
        path: 'persons',
        loadChildren: () =>
          import('./person/person.module').then((m) => m.PersonModule),
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./application/application.module').then(
            (m) => m.ApplicationModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
