import {
  AfterViewInit,
  Component,
  HostListener,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { CandyDate } from 'ng-zorro-antd/core/time';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { zonedTimeToUtc } from 'date-fns-tz';

export type AgGridCellDatePickerParams = {
  dateOnly: boolean;
};

@Component({
  selector: 'tl-editor-cell-selector',
  template: ` <nz-date-picker
    #editor
    [nzFormat]="dateFormat"
    [nzAllowClear]="true"
    (ngModelChange)="valueChanged($event)"
    style="width: 100%; height: 100%"
  ></nz-date-picker>`,
})
export class AgGridCellDatePickerComponent
  implements AgEditorComponent, AfterViewInit
{
  private params!: ICellEditorParams & AgGridCellDatePickerParams;
  public value: any;
  public closing = false;
  public enterClicked = false;
  public initializingValue = false;

  public dateFormat = 'dd.MM.yyyy';
  public dateOnly = true;
  public returnAsString = false;

  @ViewChild('editor')
  public input!: NzDatePickerComponent;

  @HostListener('keydown.enter', ['$event'])
  onEnter() {
    this.enterClicked = true;
  }

  ngAfterViewInit() {
    // focus on the input
    if (this.params.charPress) {
      this.value = undefined;
      this.input.inputValue = this.params.charPress;
    } else {
      this.value = this.params.value;
      this.input.ngOnChanges({
        ngModel: new SimpleChange(undefined, 'value', true),
      });
      if (this.params.value) {
        let date: Date = this.params.value;
        if (typeof this.params.value === 'string') {
          date = new Date(this.params.value);
          this.returnAsString = true;
        }
        this.input.inputValue = format(date, this.dateFormat, { locale: ru });
      }
    }
    setTimeout(() => {
      this.input.focus();
      if (this.params?.eventKey !== 'Enter') {
        this.input.open();
      }
    });
  }

  agInit(params: ICellEditorParams & AgGridCellDatePickerParams): void {
    this.params = params;
    if (params.eventKey === 'Backspace' || params.eventKey === 'Delete') {
      this.value = undefined;
    } else {
      this.value = this.params.value;
    }
    this.dateOnly = params.dateOnly !== false;
    this.initializingValue = true;
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    // this simple editor doubles any value entered into the input

    if (!this.dateOnly) {
      throw new Error('41e51775-8cc4-4a40-be7f-a5631dc7650b');
    }

    const date: CandyDate | null = this.input['checkValidDate'](
      this.input.inputValue
    );
    if (!date) return;

    const utcDate = zonedTimeToUtc(date.nativeDate, 'UTC');

    if (this.returnAsString) {
      return utcDate.toISOString();
    }
    return utcDate;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  valueChanged(value: any) {
    this.value = value;

    const shouldClose = !this.initializingValue;

    if (this.initializingValue) {
      this.initializingValue = false;
    }

    if (!shouldClose) return;

    this.closing = true;

    setTimeout(() => {
      this.params.api.stopEditing(false);
      if (this.enterClicked) {
        this.goToNextOrCurrentCell();
      } else {
        const cell = this.params.api.getFocusedCell();
        if (cell) {
          this.params.api.setFocusedCell(cell.rowIndex, cell.column);
        }
      }
    });
  }

  openChanged($event: any) {
    if ($event) return;
    if (this.closing) return;
    // окно закрылось, но ничего не отредактировалось
    this.params.api.stopEditing(true);
    // if (this.enterClicked) {
    this.goToNextOrCurrentCell();
    // } else {
    //   const cell = this.params.api.getFocusedCell();
    //   if (cell) {
    //     this.params.api.setFocusedCell(cell.rowIndex, cell.column);
    //   }
    // }
  }

  goToNextOrCurrentCell() {
    if (this.params.api.tabToNextCell()) return;
    const cell = this.params.api.getFocusedCell();
    if (cell) {
      this.params.api.setFocusedCell(cell.rowIndex, cell.column);
    }
  }
}
