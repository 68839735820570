import { Injectable } from '@angular/core';
import { Prisma, Requirement } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

@Injectable({ providedIn: 'root' })
export class RequirementService extends CRUDService<Requirement> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'requirement',
      plural: 'requirements',
      list: `
        ${Prisma.RequirementScalarFieldEnum.id}
        ${Prisma.RequirementScalarFieldEnum.name}
        ${Prisma.RequirementScalarFieldEnum.groupName}
        ${Prisma.RequirementScalarFieldEnum.description}
        ${Prisma.RequirementScalarFieldEnum.technicalDocumentId}
        technicalDocument {
          ${Prisma.TechnicalDocumentScalarFieldEnum.id}
          ${Prisma.TechnicalDocumentScalarFieldEnum.name}
        }
        ${Prisma.RequirementScalarFieldEnum.technicalDocumentArticle}
        ${Prisma.RequirementScalarFieldEnum.materialPropertyId}
        materialProperty {
          ${Prisma.RequirementScalarFieldEnum.id}
          ${Prisma.RequirementScalarFieldEnum.name}
        }
        ${Prisma.RequirementScalarFieldEnum.standardDescription}
        ${Prisma.RequirementScalarFieldEnum.productGroupDescription}
        ${Prisma.RequirementScalarFieldEnum.uomId}
        uom {
          ${Prisma.UnitOfMeasurementScalarFieldEnum.name}
        }
      `,
    });
  }
}
