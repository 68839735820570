// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorsOf = (error: any): string[] => {
  if (!error) return [];

  if (typeof error === 'string') return [error];

  if (error.graphQLErrors && error.graphQLErrors.length) {
    return error.graphQLErrors.map((item: unknown) => errorsOf(item)[0]);
  }

  if (typeof error.message === 'string') return [error.message];

  if (error.message && typeof error.message.message === 'string')
    return [error.message.message];

  if (Array.isArray(error)) return error;
  return [error];
};
