import { Agreement } from '@prisma/client';

export function agreementName(agreement: Agreement): string {
  if (!agreement) return '';
  let name = agreement.number || 'б/н';
  if (agreement.signDate) {
    name += ' от ' + new Date(agreement.signDate).toLocaleDateString();
  }
  return name;
}
