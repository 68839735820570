import { NgModule } from '@angular/core';
import { EffectsNgModule } from '@ngneat/effects-ng';
import { AgreementEffects } from '../agreement/state/agreement.effects';
import { ApplicationEffects } from '../application/state/application.effects';
import { CountryEffects } from '../country/state/country.effects';
import { EquipmentEffects } from '../equipment/state/equipment.effects';
import { MaterialPropertyEffects } from '../material-property/state/material-property.effects';
import { PersonEffects } from '../person/state/person.effects';
import { QuantityEffects } from '../quantity/state/quantity.effects';
import { RequirementEffects } from '../requirement/state/requirement.effects';
import { SubstanceEffects } from '../substance/state/substance.effects';
import { TechnicalDocumentEffects } from '../technical-document/state/technical-document.effects';
import { TestMethodEffects } from '../test-method/state/test-method.effects';
import { UnitOfMeasurementEffects } from '../unit-of-measurement/state/unit-of-measurement.effects';

@NgModule({
  imports: [
    EffectsNgModule.forFeature([
      EquipmentEffects,
      TechnicalDocumentEffects,
      QuantityEffects,
      MaterialPropertyEffects,
      SubstanceEffects,
      RequirementEffects,
      TestMethodEffects,
      UnitOfMeasurementEffects,
      CountryEffects,
      PersonEffects,
      AgreementEffects,
      ApplicationEffects,
    ]),
  ],
})
export class StateModule {}
