import { Injectable } from '@angular/core';
import { Prisma, UnitOfMeasurement } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

@Injectable({ providedIn: 'root' })
export class CountryService extends CRUDService<UnitOfMeasurement> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'country',
      plural: 'countries',
      list: `
        ${Prisma.CountryScalarFieldEnum.id}
        ${Prisma.CountryScalarFieldEnum.name}
      `,
    });
  }
}
