import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'quantity';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка измеряемых величин',
  successMessage: 'Список измеряемых величин загружен',
  failMessage: 'Ошибка при загрузке списка измеряемых величин',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка измеряемой величины',
  successMessage: 'Измеряемая величина загружена',
  failMessage: 'Ошибка при загрузке измеряемой величины',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание измеряемой величины',
  successMessage: 'Измеряемая величина создана',
  failMessage: 'Ошибка при создании измеряемой величины',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление измеряемой величины',
  successMessage: 'Измеряемая величина обновлена',
  failMessage: 'Ошибка при обновлении измеряемой величины',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление измеряемой величины',
  successMessage: 'Измеряемая величина удалена',
  failMessage: 'Ошибка при удалении измеряемой величины',
});
