import { actionsFactory } from '@ngneat/effects';
import * as pluralize from 'pluralize';
import {
  createItemActions,
  deleteItemActions,
  loadItemActions,
  loadListActions,
  updateItemActions,
} from '../../shared/crud/actions';

const entityName = 'requirement';
export const actions = actionsFactory(pluralize(entityName));

export const loadList = loadListActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка списка требований',
  successMessage: 'Список требований загружен',
  failMessage: 'Ошибка при загрузке списка требований',
});

export const loadItem = loadItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Загрузка требования',
  successMessage: 'Требование загружено',
  failMessage: 'Ошибка при загрузке требования',
});

export const createItem = createItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Создание требования',
  successMessage: 'Требование создано',
  failMessage: 'Ошибка при создании требования',
});

export const updateItem = updateItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Обновление требования',
  successMessage: 'Требование обновлено',
  failMessage: 'Ошибка при обновлении требования',
});

export const deleteItem = deleteItemActions({
  entityName: entityName,
  factory: actions,
  actionMessage: 'Удаление требования',
  successMessage: 'Требование удалено',
  failMessage: 'Ошибка при удалении требования',
});
