import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Quantity } from '@prisma/client';
import { CRUDRepository } from '../../shared/crud/repository';
import { loadList } from './quantity.actions';

type StatusPaths = 'list' | 'item';

const { state, config } = createState(
  withEntities<Quantity>(),
  withActiveId(),
  withRequestsStatus<StatusPaths>()
);

export const store = new Store({ name: 'quantities', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class QuantityRepository extends CRUDRepository<Quantity, typeof store> {
  constructor() {
    super(store, { loadListAction: loadList });
  }

  public override searchEntity(entity: Quantity, searchText: string) {
    searchText = searchText.toLowerCase();
    if (entity.name) {
      if (entity.name?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    return false;
  }
}
