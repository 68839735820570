import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import {
  NzSelectComponent,
  NzSelectModeType,
  NzSelectOptionInterface,
} from 'ng-zorro-antd/select';
import { CRUDRepository } from '../../crud/repository';

export interface AgGridCellSelectorParams {
  repository?: CRUDRepository;
  options?: NzSelectOptionInterface[];
  mode?: NzSelectModeType;
  maxTagCount?: number;
}

@Component({
  selector: 'tl-editor-cell-selector',
  template: ` <nz-select
    #editor
    nzBorderless="true"
    nzShowSearch
    nzAllowClear
    [nzMaxTagCount]="maxTagCount"
    [nzOpen]="true"
    [nzOptions]="options"
    [nzMode]="mode"
    [repository]="repository"
    [nzLoading]="(repository.listLoaded$ | async) === false"
    [ngModel]="value"
    (ngModelChange)="valueChanged($event)"
    (nzOpenChange)="openChanged($event)"
    style="width: 100%; height: 100%"
  ></nz-select>`,
  styles: [
    `
      ::ng-deep .ant-select-multiple .ant-select-selector {
        flex-wrap: nowrap;
      }
    `,
  ],
})
export class AgGridCellSelectorComponent
  implements AgEditorComponent, AfterViewInit
{
  private params!: ICellEditorParams & AgGridCellSelectorParams;
  public value: any;
  public repository!: CRUDRepository;
  public closing = false;
  public enterClicked = false;
  public options: NzSelectOptionInterface[] = [];
  public mode: NzSelectModeType = 'default';
  public maxTagCount = Infinity;

  @ViewChild('editor')
  public input!: NzSelectComponent;

  @HostListener('keydown.enter', ['$event'])
  onEnter() {
    this.enterClicked = true;
  }

  ngAfterViewInit() {
    // focus on the input
    setTimeout(() => {
      this.input.focus();
      if (this.params.charPress) {
        this.input.nzSelectTopControlComponent.nzSelectSearchComponent.onValueChange(
          this.params.charPress
        );
      }
    });
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    if (params.eventKey === 'Backspace' || params.eventKey === 'Delete') {
      this.value = undefined;
    } else {
      this.value = this.params.value;
    }
    if (this.params?.repository) {
      this.repository = this.params.repository;
    }
    if (this.params?.options) {
      this.options = this.params.options;
    }
    if (this.params?.mode) {
      this.mode = this.params.mode;
    }
    this.maxTagCount = this.params.maxTagCount ?? Infinity;
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    // this simple editor doubles any value entered into the input
    return this.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  valueChanged(value: any) {
    this.value = value;

    const shouldClose = !this.params.mode || this.params.mode === 'default';
    if (!shouldClose) return;

    this.closing = true;

    setTimeout(() => {
      this.params.api.stopEditing(false);
      if (this.enterClicked) {
        this.goToNextOrCurrentCell();
      } else {
        const cell = this.params.api.getFocusedCell();
        if (cell) {
          this.params.api.setFocusedCell(cell.rowIndex, cell.column);
        }
      }
    });
  }

  openChanged($event: any) {
    if ($event) return;
    if (this.closing) return;
    // окно закрылось, но ничего не отредактировалось
    this.params.api.stopEditing(true);
    // if (this.enterClicked) {
    this.goToNextOrCurrentCell();
    // } else {
    //   const cell = this.params.api.getFocusedCell();
    //   if (cell) {
    //     this.params.api.setFocusedCell(cell.rowIndex, cell.column);
    //   }
    // }
  }

  goToNextOrCurrentCell() {
    if (this.params.api.tabToNextCell()) return;
    const cell = this.params.api.getFocusedCell();
    if (cell) {
      this.params.api.setFocusedCell(cell.rowIndex, cell.column);
    }
  }
}
