import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Agreement } from '@prisma/client';
import { CRUDRepository } from '../../shared/crud/repository';
import { agreementName } from '../agreement.utils';
import { loadList } from './agreement.actions';

type StatusPaths = 'list' | 'item';

const { state, config } = createState(
  withEntities<Agreement>(),
  withActiveId(),
  withRequestsStatus<StatusPaths>()
);

export const store = new Store({ name: 'agreements', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class AgreementRepository extends CRUDRepository<
  Agreement,
  typeof store
> {
  constructor() {
    super(store, { loadListAction: loadList });
  }

  public override lookupLabel(entity: Agreement) {
    return agreementName(entity);
  }
}
