import { Injectable } from '@angular/core';
import { Equipment, Prisma } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

@Injectable({ providedIn: 'root' })
export class EquipmentService extends CRUDService<Equipment> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'equipmentItem',
      plural: 'equipment',
      list: `
        ${Prisma.EquipmentScalarFieldEnum.id}
        ${Prisma.EquipmentScalarFieldEnum.systemName}
        ${Prisma.EquipmentScalarFieldEnum.name}
        ${Prisma.EquipmentScalarFieldEnum.type}
        ${Prisma.EquipmentScalarFieldEnum.serialNumber}
        ${Prisma.EquipmentScalarFieldEnum.inventoryNumber}
        ${Prisma.EquipmentScalarFieldEnum.verificationDate}
        ${Prisma.EquipmentScalarFieldEnum.startDate}
        ${Prisma.EquipmentScalarFieldEnum.endDate}
      `,
    });
  }
}
