import { Injectable } from '@angular/core';
import { Prisma, TechnicalDocument } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

@Injectable({ providedIn: 'root' })
export class TechnicalDocumentService extends CRUDService<TechnicalDocument> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'technicalDocument',
      plural: 'technicalDocuments',
      list: `
        ${Prisma.TechnicalDocumentScalarFieldEnum.id}
        ${Prisma.TechnicalDocumentScalarFieldEnum.name}
        ${Prisma.TechnicalDocumentScalarFieldEnum.number}
        ${Prisma.TechnicalDocumentScalarFieldEnum.title}
        ${Prisma.TechnicalDocumentScalarFieldEnum.startDate}
        ${Prisma.TechnicalDocumentScalarFieldEnum.endDate}
      `,
    });
  }
}
