import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DevService {
  devModeInited = false;
  devMode = false;
  devMode$ = new BehaviorSubject<boolean>(false);
  devModeInited$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly router: Router) {}

  toggleDevMode(event: KeyboardEvent) {
    if (environment.production) return;
    if (event.ctrlKey && event.altKey && event.code?.toLowerCase() === 'keyd') {
      this.devMode = !this.devMode;
      if (this.devMode && !this.devModeInited) {
        this.devModeInited$.next(true)
      }
      this.devMode$.next(this.devMode);
      if (this.devMode) {
        const hasDevRoute = !!this.router.routerState.root.children.find(
          (i) => i.outlet === 'dev'
        );
        if (!hasDevRoute) {
          this.router.navigate([
            '/',
            {
              outlets: {
                dev: ['dev', 'entities'],
              },
            },
          ]);
        }
      } else {
        // this.router.navigate([
        //   {
        //     outlets: {
        //       dev: null,
        //     },
        //   },
        // ]);
      }
    }
  }
}
