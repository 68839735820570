/* eslint-disable @typescript-eslint/no-explicit-any */
import { diff } from 'json-diff-ts';

export function objChanges(
  oldObject: any,
  newObject: any,
  idKey: string | undefined
) {
  const nested: Record<string, unknown> = {};

  const isObject = (A: any) => {
    return (typeof A === 'object' || typeof A === 'function') && A !== null;
  };

  const nestedKeys = (
    obj: any,
    keys: Record<string, unknown>,
    path?: string
  ) => {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        if (!(obj[key] as any[]).length) continue;

        const prop = path ? path + '.' + key : key;
        if (isObject(obj[key][0])) {
          keys[prop] = idKey;
          nestedKeys(obj[key][0], keys, prop);
        } else {
          keys[prop] = '$index';
        }
      }
    }
    return keys;
  };

  if (idKey) {
    nestedKeys(oldObject, nested);
    nestedKeys(newObject, nested);
  }

  return diff(oldObject, newObject, nested);
}
