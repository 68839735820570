import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Equipment } from '@prisma/client';
import { CRUDRepository } from '../../shared/crud/repository';
import { loadList } from './equipment.actions';

const { indicatorEntitiesRef, withIndicatorEntities } =
  entitiesPropsFactory('indicator');

interface IndicatorItem {
  id: string;
  equipmentId: Equipment['id'];
  quantity: number;
}

type StatusPaths = 'list' | 'item';

const { state, config } = createState(
  withEntities<Equipment>(),
  withActiveId(),
  withIndicatorEntities<IndicatorItem>(),
  withRequestsStatus<StatusPaths>()
);

export const store = new Store({ name: 'equipment', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class EquipmentRepository extends CRUDRepository<
  Equipment,
  typeof store
> {
  constructor() {
    super(store, { loadListAction: loadList });
  }

  public override searchEntity(entity: Equipment, searchText: string) {
    searchText = searchText.toLowerCase();
    if (entity.systemName) {
      if (entity.systemName?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.name) {
      if (entity.name?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.serialNumber) {
      if (entity.serialNumber?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.inventoryNumber) {
      if (
        entity.inventoryNumber?.toLocaleLowerCase().indexOf(searchText) !== -1
      )
        return true;
    }
    return false;
  }
}
