import { Injectable } from '@angular/core';
import { Agreement, Prisma } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

const listProperties = `
  ${Prisma.AgreementScalarFieldEnum.id}
  ${Prisma.AgreementScalarFieldEnum.number}
  ${Prisma.AgreementScalarFieldEnum.signDate}
  ${Prisma.AgreementScalarFieldEnum.personId}
`;

const entityProperies =
  listProperties +
  `
  person {
    ${Prisma.PersonScalarFieldEnum.name}
  }
`;

@Injectable({ providedIn: 'root' })
export class AgreementService extends CRUDService<Agreement> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'agreement',
      plural: 'agreements',
      list: listProperties,
      entity: entityProperies,
    });
  }
}
