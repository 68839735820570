import { Injectable } from '@angular/core';
import { Prisma, TestMethod } from '@prisma/client';
import { Apollo } from 'apollo-angular';
import { CRUDService } from '../../shared/crud/service';

const listProperties = `
  ${Prisma.TestMethodScalarFieldEnum.id}
  ${Prisma.TestMethodScalarFieldEnum.name}
  ${Prisma.TestMethodScalarFieldEnum.technicalDocumentId}
  technicalDocument {
    ${Prisma.TechnicalDocumentScalarFieldEnum.id}
    ${Prisma.TechnicalDocumentScalarFieldEnum.name}
  }
  ${Prisma.TestMethodScalarFieldEnum.technicalDocumentArticle}
`;

const entityProperies =
  listProperties +
  `
  equipment {
    id
    equipmentId
    equipmentItem {
      ${Prisma.EquipmentScalarFieldEnum.id}
      ${Prisma.EquipmentScalarFieldEnum.systemName}
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class TestMethodService extends CRUDService<TestMethod> {
  constructor(public readonly apollo: Apollo) {
    super(apollo, {
      singular: 'testMethod',
      plural: 'testMethods',
      list: listProperties,
      entity: entityProperies,
    });
  }
}
