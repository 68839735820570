import {
  Directive,
  Input,
  OnInit,
  SimpleChange,
  TemplateRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { CRUDRepository } from '../../crud/repository';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'nz-select[repository]',
})
export class SelectorStoreDirective implements OnInit {
  @Input() repository!: CRUDRepository;
  @Input() labelTeplate!: TemplateRef<unknown>;

  constructor(private readonly control: NgControl) {}

  ngOnInit(): void {
    const component = this.control.valueAccessor as NzSelectComponent;
    if (this.repository) {
      this.repository.entities$
        .pipe(untilDestroyed(this))
        .subscribe((entities) => {
          component.nzOptions = entities.map((item) => ({
            value: item.id,
            label:
              this.labelTeplate || item.systemName || item.name || item.number,
          }));

          // Trigger nzOptions update
          component.ngOnChanges({
            nzOptions: new SimpleChange(undefined, undefined, true),
          });
        });
    }
  }
}
