import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { MaterialProperty } from '@prisma/client';
import { CRUDRepository } from '../../shared/crud/repository';
import { loadList } from './material-property.actions';
import { MaterialPropertyListItemGQL } from './material-property.interface';

type StatusPaths = 'list' | 'item';

const { state, config } = createState(
  withEntities<MaterialProperty>(),
  withActiveId(),
  withRequestsStatus<StatusPaths>()
);

export const store = new Store({ name: 'material-properties', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class MaterialPropertyRepository extends CRUDRepository<
  MaterialProperty,
  typeof store
> {
  constructor() {
    super(store, { loadListAction: loadList });
  }

  public override searchEntity(
    entity: MaterialPropertyListItemGQL,
    searchText: string
  ) {
    searchText = searchText.toLowerCase();
    if (entity.name) {
      if (entity.name?.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.quantity?.name) {
      if (entity.quantity?.name.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    if (entity.substance?.name) {
      if (entity.substance?.name.toLocaleLowerCase().indexOf(searchText) !== -1)
        return true;
    }
    return false;
  }

}
