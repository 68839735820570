import { Injectable } from '@angular/core';
import { createState, select, Store, withProps } from '@ngneat/elf';
import {
  createRequestsStatusOperator,
  StatusState,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { distinctUntilChanged, filter } from 'rxjs/operators';

export type RefreshingTokenType =
  | false
  | 'initial'
  | 'from_interceptor'
  | 'other';

export interface AuthState {
  access_token: string | null;
  refresh_token: string | null;
  refreshingToken: RefreshingTokenType;
  displayName: string | null;
  companyName: string | null;
}

const { state, config } = createState(
  withProps<AuthState>({
    displayName: localStorage.getItem('display_name'),
    companyName: localStorage.getItem('company_short_name'),
    refreshingToken: false,
    refresh_token: localStorage.getItem('refresh_token'),
    access_token: localStorage.getItem('access_token'),
  }),
  withRequestsStatus<'status'>()
);

export const store = new Store({ name: 'auth', config, state });
export const trackRequestsStatus = createRequestsStatusOperator(store);

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  isLoggedIn$ = store.pipe(select((state) => !!state.access_token));
  loggedIn$ = store.pipe(
    select((state) => state.access_token),
    filter((access_token) => !!access_token),
    distinctUntilChanged()
  );
  loggedOut$ = store.pipe(
    select((state) => state.access_token),
    filter((access_token) => !access_token)
  );
  companyName$ = store.pipe(select((state) => state.companyName));
  displayName$ = store.pipe(select((state) => state.displayName));

  isLoggedIn() {
    return !!store.getValue().access_token || !!this.access_token();
  }

  access_token() {
    return store.getValue().access_token;
  }

  refreshingToken() {
    return store.getValue().refreshingToken;
  }

  startRefreshToken(type: Exclude<RefreshingTokenType, false>) {
    if (this.refreshingToken() === type) return;
    store.update((state) => ({
      ...state,
      refreshingToken: type,
    }));
  }

  endRefreshToken() {
    if (this.refreshingToken() === false) return;
    store.update((state) => ({
      ...state,
      refreshingToken: false,
    }));
  }

  refresh_token() {
    return store.getValue().refresh_token;
  }

  update(authState: Partial<AuthState>) {
    store.update((state) => ({
      ...state,
      authState,
    }));
  }

  reset() {
    store.reset();
  }

  public updateStatus(status: Exclude<StatusState['value'], 'error'>) {
    store.update(updateRequestStatus('status', status));
  }

  updateTokenData(
    tokens: { refresh_token: string; access_token: string } | undefined,
    status: Exclude<StatusState['value'], 'error'> = 'success'
  ) {
    if (tokens && tokens?.refresh_token && tokens?.access_token) {
      store.update(
        (state) => ({
          ...state,
          refresh_token: tokens?.refresh_token,
          access_token: tokens?.access_token,
          refreshingToken: false,
        }),
        status ? updateRequestStatus('status', status) : (state) => state
      );
      localStorage.setItem('refresh_token', tokens?.refresh_token || '');
      localStorage.setItem('access_token', tokens?.access_token || '');
    } else {
      this.clearTokens();
    }
  }

  clearTokens() {
    store.update((state) => ({
      ...state,
      refresh_token: null,
      access_token: null,
      refreshingToken: false,
    }));
    // localStorage.setItem(
    //   'refresh_token1',
    //   localStorage.getItem('refresh_token') || ''
    // );
    // localStorage.setItem(
    //   'access_token1',
    //   localStorage.getItem('access_token') || ''
    // );
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
  }
}
