import { Component } from '@angular/core';
import { version } from '@testing-lab-mono/version';
import { DevService } from '../dev/dev.service';

@Component({
  selector: 'tl-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  isCollapsed = false;
  public version = version;

  constructor(public readonly devService: DevService) {}
}
